<template>
  <div>
    <back-nav to="customerDetails" />
    <h2 class="tw-text-xl tw-mt-2 c-gray-text">Repair Parts</h2>
    <hr />
    <div class="tw-py-2 md:tw-py-4">
      <p>
        Submit your Bid on the Vehicle for confirmation of the estimated prices
        of vehicle parts before repairs and quantity required, grade and quality
        of the parts as well as their prices.
      </p>
    </div>
    <div>
      <hr />
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col" class="text-left">Parts/Services</th>
              <th scope="col" class="text-left">Contribution(%)</th>
              <th scope="col" class="text-left">Type</th>
              <th scope="col" class="text-left">Quantity</th>
              <th scope="col" class="text-left">Unit Price</th>
              <th scope="col" class="text-left">Total</th>
              <th scope="col" class="text-left">VAT</th>
              <th scope="col" class="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in parts"
              :key="index"
              :ref="`edit-parts-${index}`"
            >
              <template v-if="item.editable">
                <td>
                  <v-combobox
                    v-model="item.part"
                    dense
                    class="mt-4"
                    :items="vehicleParts.map((part) => part.name)"
                    :rules="[required('Parts')]"
                    placeholder="Parts"
                    aria-sort="descending"
                    value="0"
                  ></v-combobox>
                </td>
                <td>
                  <v-select
                    v-model="item.contribution"
                    dense
                    class="mt-4"
                    :items="contribution"
                    placeholder="contribution"
                  ></v-select>
                </td>
                <td>
                  <v-select
                    v-model="item.type"
                    dense
                    class="mt-4"
                    :items="types"
                    placeholder="Type"
                    :rules="[required('Type')]"
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    type="number"
                    v-model="item.quantity"
                    placeholder="Pieces"
                    :rules="[required('Pieces')]"
                  ></v-text-field>
                </td>
                <td>
                  <vuetify-money
                    v-model="item.unitPrice"
                    placeholder="Unit Price"
                    :rules="[required('Unit price')]"
                    :options="{ precision: 0 }"
                  ></vuetify-money>
                </td>
                <td>{{ getTotal(item) | formatCurrency }}</td>
                <td>
                  <v-switch
                    class="tw-mr-1"
                    v-model="item.vat"
                    label="VAT"
                  ></v-switch>
                </td>
                <td>
                  <v-btn
                    color="success"
                    x-small
                    v-model="isValid"
                    @click="editPart(index, false)"
                    >Update Part</v-btn
                  >
                </td>
              </template>
              <template v-else>
                <td>{{ item.part }}</td>
                <td>{{ item.contribution }}%</td>
                <td>{{ item.type }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.unitPrice | formatCurrency }}</td>
                <td>{{ getTotal(item) | formatCurrency }}</td>
                <td>
                  <v-switch v-model="item.vat"></v-switch>
                </td>
                <td>
                  <div class="tw-flex tw-justify-center tw-items-center">
                    <v-btn
                      color="success"
                      @click="editPart(index)"
                      class="tw-mr-1"
                      x-small
                      >Edit Part</v-btn
                    >

                    <v-btn color="error" x-small @click="removePart(item.part)"
                      >Remove</v-btn
                    >
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <hr />
      <v-form v-model="isValid" ref="parts">
        <div
          class="tw-grid tw-grid-cols-1 md:tw-grid-cols-6 tw-gap-2 md:tw-gap-8 tw-items-center mr-16"
        >
          <div>
            <v-combobox
              v-model="form.part"
              dense
              class="mt-4"
              :items="vehicleParts.map((part) => part.name).sort()"
              :rules="[required('Parts')]"
              placeholder="Parts"
            ></v-combobox>
          </div>
          <div>
            <v-select
              v-model="form.contribution"
              dense
              class="mt-4"
              :items="contribution"
              placeholder="contribution"
            ></v-select>
          </div>
          <div class="tw-mt-0 md:tw-mt-3">
            <v-select
              v-model="form.type"
              dense
              :items="types"
              placeholder="Type"
              :rules="[required('Type')]"
            ></v-select>
          </div>
          <div>
            <v-text-field
              type="number"
              v-model="form.quantity"
              placeholder="Pieces"
              :rules="[required('Pieces')]"
            ></v-text-field>
          </div>
          <div>
            <vuetify-money
              v-model="form.unitPrice"
              placeholder="Unit Price"
              :rules="[required('Unit price')]"
              :options="{ precision: 0 }"
            ></vuetify-money>
          </div>
          <div class="tw-flex tw-justify-center tw-items-center tw-space-x-1">
            <v-switch class="tw-mr-1" v-model="form.vat" label="VAT"></v-switch>
            <v-btn
              color="success"
              x-small
              :disabled="!isValid"
              @click="addParts"
              >Add Part</v-btn
            >
            <v-icon class="tw-cursor-pointer" color="error" @click="clearForm"
              >mdi-close</v-icon
            >
          </div>
        </div>
      </v-form>
    </div>
    <hr />
    <div class="tw-flex tw-justify-start md:tw-justify-end tw-py-2 md:tw-py-4">
      <div class="tw-w-full md:tw-w-1/3">
        <div class="tw-grid tw-grid-cols-2 c-gray-text">
          <h6>Net Total</h6>
          <h6>Ksh. {{ netTotal | formatCurrency }}</h6>
        </div>
        <div class="tw-grid tw-grid-cols-2 c-gray-text">
          <h6>VAT 16%</h6>
          <h6>Ksh. {{ vatTotal | formatCurrency }}</h6>
        </div>
        <div
          v-if="contributionTotal > 0"
          class="tw-grid tw-grid-cols-2 c-gray-text"
        >
          <h6>Client contribution</h6>
          <h6>(Ksh. {{ contributionTotal | formatCurrency }})</h6>
        </div>
        <div
          class="tw-grid tw-grid-cols-2 c-gray-text tw-text-xl md:tw-text-2xl"
        >
          <h6>TOTAL</h6>
          <h6>Ksh. {{ grandTotal | formatCurrency }}</h6>
        </div>
      </div>
    </div>
    <hr class="tw-mb-5" />
    <div class="white-section-section tw-py-4 tw-px-4">
      <h2 class="tw-text-xl tw-mt-2 c-gray-text">Additional Information</h2>
      <hr />
      <div class="tw-py-2 md:tw-py-4">
        <v-textarea
          v-model="additionalInformation"
          outlined
          rows="3"
          :rules="[required('Additional Information')]"
        ></v-textarea>
      </div>
    </div>
    <div class="light-gray-section tw-py-4 tw-px-4">
      <h2 class="tw-text-xl tw-mt-2 c-gray-text">
        Internal Private Communication
      </h2>
      <hr />
      <div class="tw-py-2 md:tw-py-4">
        <v-textarea
          v-model="confidentialInformationFromExternalAssessor"
          outlined
          rows="3"
          :rules="[required('Internal Private Communication')]"
        ></v-textarea>
      </div>
      <div class="tw-flex tw-justify-center">
        <div
          class="tw-w-2/3 md:tw-w-1/3 tw-py-4 md:tw-py-10 tw-space-x-2 tw-flex tw-justify-center"
        >
          <v-btn
            color="primary"
            outlined
            @click="
              $root.$emit('nav-back-to', { currentTab: 'customerDetails' })
            "
            >Previous</v-btn
          >
          <v-btn
            color="primary"
            @click="processStep"
            :loading="loading"
            :disabled="disableNextButton"
            >Next</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VAT } from '@/utils/const'
import BackNav from '@/components/BackNav'
import Validations from '@/utils/Validations'
import { arrayRange, formatCurrency } from '@/utils/helpers'
import biddingFormMixin from '@/mixins/biddingFormMixin'

export default {
  name: 'repairParts',
  props: {
    booking: Object,
    biddingTemplate: Object,
    vehicleParts: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: { BackNav },
  mixins: [biddingFormMixin],
  data() {
    return {
      types: ['OEM', 'Open Market'],
      additionalInformation: '',
      confidentialInformationFromExternalAssessor: '',
      parts: [],
      contribution: arrayRange(0, 100, 10),
      isValid: false,
      form: {
        part: '',
        contribution: 0,
        type: '',
        unitPrice: 0,
        quantity: null,
        vat: false,
      },
      ...Validations,
      vatRate: VAT,
      loading: false,
      nextSection: 'to-vehicle-photos',
    }
  },
  computed: {
    disableNextButton: function () {
      return (
        this.parts.length === 0 ||
        !this.additionalInformation ||
        !this.confidentialInformationFromExternalAssessor ||
        this.parts.some((part) => part.editable === true)
      )
    },
  },
  methods: {
    validateText(text) {
      const isValidPart =
        this.vehicleParts.filter((vehiclePart) => vehiclePart.name === text)
          .length === 1
      if (!isValidPart)
        this.showSnackbarMessage(
          'failed',
          'Select a valid vehicle part',
          'failed'
        )
      return isValidPart
    },

    addParts() {
      if (this.validateText(this.form.part)) {
        this.parts.push({ ...this.form })
        this.$refs.parts.reset()
      }
    },
    removePart(part) {
      this.parts = this.parts.filter((i) => i.part !== part)
    },
    clearForm() {
      this.$refs.parts.reset()
      this.form.vat = false
    },
    editPart: function (index, type = true) {
      this.$set(this.parts[index], 'editable', type)
    },
    updateForm: function () {
      if (this.biddingTemplate) {
        this.additionalInformation =
          this.biddingTemplate.additionalInformation || ''
        this.confidentialInformationFromExternalAssessor =
          this.biddingTemplate.confidentialInformationFromExternalAssessor || ''
        this.parts = this.biddingTemplate.repairs || []
        this.updateParts()
      }
    },
  },
  filters: {
    formatCurrency,
  },
}
</script>

<style>
.light-gray-section {
  background-color: #f5f5f5;
  border-radius: 8px;
}
.white-section {
  background: #ffffff;
  border-radius: 8px;
}
</style>
